exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bemutatkozas-tsx": () => import("./../../../src/pages/bemutatkozas.tsx" /* webpackChunkName: "component---src-pages-bemutatkozas-tsx" */),
  "component---src-pages-csapatunk-tagjai-tsx": () => import("./../../../src/pages/csapatunk_tagjai.tsx" /* webpackChunkName: "component---src-pages-csapatunk-tagjai-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kapcsolat-tsx": () => import("./../../../src/pages/kapcsolat.tsx" /* webpackChunkName: "component---src-pages-kapcsolat-tsx" */),
  "component---src-pages-mi-a-gestalt-tsx": () => import("./../../../src/pages/mi_a_gestalt.tsx" /* webpackChunkName: "component---src-pages-mi-a-gestalt-tsx" */),
  "component---src-pages-programok-tsx": () => import("./../../../src/pages/programok.tsx" /* webpackChunkName: "component---src-pages-programok-tsx" */),
  "component---src-pages-szolgaltatasok-tsx": () => import("./../../../src/pages/szolgaltatasok.tsx" /* webpackChunkName: "component---src-pages-szolgaltatasok-tsx" */)
}

